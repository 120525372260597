.LinearAsync {
  position: fixed;
  width: 100%;
  top: 0;
  z-index:5000;
}

.hidden {
  visibility: hidden;
}

.super-hidden {
  display: none;
}
