body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.fade-enter {
  opacity: .85;
  z-index: 1; }

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.flex-grow {
  flex-grow: 1; }

.text-align-right {
  text-align: right; }

.text-align-center {
  text-align: center; }

.text-align-left {
  text-align: left; }

img {
  -o-user-select: none;
  -webkit-user-select: none;
  user-select: none; }

a.active {
  color: #0086C0; }

body {
  color: #255884; }

h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -1.5px; }
  @media (min-width: 0px) {
    h1 {
      font-size: calc(48px + 1vmin); } }
  @media (min-width: 600px) {
    h1 {
      font-size: calc(48px + 1vmin); } }
  @media (min-width: 960px) {
    h1 {
      font-size: calc(48px + 0vmin); } }
  @media (min-width: 1280px) {
    h1 {
      font-size: calc(48px + 0vmin); } }
  @media (min-width: 1920px) {
    h1 {
      font-size: 48px; } }

h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.5px; }
  @media (min-width: 0px) {
    h2 {
      font-size: calc(60px + 1vmin); } }
  @media (min-width: 600px) {
    h2 {
      font-size: calc(60px + 1vmin); } }
  @media (min-width: 960px) {
    h2 {
      font-size: calc(60px + 0vmin); } }
  @media (min-width: 1280px) {
    h2 {
      font-size: calc(60px + 0vmin); } }
  @media (min-width: 1920px) {
    h2 {
      font-size: 60px; } }

h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: normal; }
  @media (min-width: 0px) {
    h3 {
      font-size: calc(48px + 1vmin); } }
  @media (min-width: 600px) {
    h3 {
      font-size: calc(48px + 1vmin); } }
  @media (min-width: 960px) {
    h3 {
      font-size: calc(48px + 0vmin); } }
  @media (min-width: 1280px) {
    h3 {
      font-size: calc(48px + 0vmin); } }
  @media (min-width: 1920px) {
    h3 {
      font-size: 48px; } }

h4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.25px; }
  @media (min-width: 0px) {
    h4 {
      font-size: calc(34px + 1vmin); } }
  @media (min-width: 600px) {
    h4 {
      font-size: calc(34px + 1vmin); } }
  @media (min-width: 960px) {
    h4 {
      font-size: calc(34px + 0vmin); } }
  @media (min-width: 1280px) {
    h4 {
      font-size: calc(34px + 0vmin); } }
  @media (min-width: 1920px) {
    h4 {
      font-size: 34px; } }

h5 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin: 10px 0; }
  @media (min-width: 0px) {
    h5 {
      font-size: calc(24px + 1vmin);
      line-height: calc(24px + 1vmin); } }
  @media (min-width: 600px) {
    h5 {
      font-size: calc(24px + 1vmin);
      line-height: calc(24px + 1vmin); } }
  @media (min-width: 960px) {
    h5 {
      font-size: calc(24px + 0vmin);
      line-height: calc(24px + 0vmin); } }
  @media (min-width: 1280px) {
    h5 {
      font-size: calc(24px + 0vmin);
      line-height: calc(24px + 0vmin); } }
  @media (min-width: 1920px) {
    h5 {
      font-size: 24px;
      line-height: 24px; } }

h6 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0; }
  @media (min-width: 0px) {
    h6 {
      font-size: calc(20px + 1vmin); } }
  @media (min-width: 600px) {
    h6 {
      font-size: calc(20px + 1vmin); } }
  @media (min-width: 960px) {
    h6 {
      font-size: calc(20px + 0vmin); } }
  @media (min-width: 1280px) {
    h6 {
      font-size: calc(20px + 0vmin); } }
  @media (min-width: 1920px) {
    h6 {
      font-size: 20px; } }

body {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.5px; }
  @media (min-width: 0px) {
    body {
      font-size: calc(18px + 1vmin);
      line-height: calc(28px + 1vmin); } }
  @media (min-width: 600px) {
    body {
      font-size: calc(18px + 1vmin);
      line-height: calc(28px + 1vmin); } }
  @media (min-width: 960px) {
    body {
      font-size: calc(18px + 0vmin);
      line-height: calc(28px + 0vmin); } }
  @media (min-width: 1280px) {
    body {
      font-size: calc(18px + 0vmin);
      line-height: calc(28px + 0vmin); } }
  @media (min-width: 1920px) {
    body {
      font-size: 18px;
      line-height: 28px; } }

small {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0.25px; }
  @media (min-width: 0px) {
    small {
      font-size: calc(14px + 1vmin);
      line-height: calc(20px + 1vmin); } }
  @media (min-width: 600px) {
    small {
      font-size: calc(14px + 1vmin);
      line-height: calc(20px + 1vmin); } }
  @media (min-width: 960px) {
    small {
      font-size: calc(14px + 0vmin);
      line-height: calc(20px + 0vmin); } }
  @media (min-width: 1280px) {
    small {
      font-size: calc(14px + 0vmin);
      line-height: calc(20px + 0vmin); } }
  @media (min-width: 1920px) {
    small {
      font-size: 14px;
      line-height: 20px; } }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.App {
  background-color: #FCFCFC;
  font-size: calc(10px + 2vmin);
  color: #255884;
  min-height: 100vh;
  padding-top: 20px; }
  .App .bold {
    font-weight: 700; }
  .App .App-header {
    text-align: center; }
  .App .App-content {
    padding-bottom: 20px; }
  .App .App-footer {
    background: #255884;
    color: white;
    padding: 5px;
    text-align: center; }
    .App .App-footer a {
      color: #fff; }
  .App .App-link {
    color: #61dafb; }

.fonts_textfield__3V7dV {
  font-family: inherit; }

.fonts_subtitle__1PQFt {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 1px; }
  @media (min-width: 0px) {
    .fonts_subtitle__1PQFt {
      font-size: calc(16px + 1vmin);
      line-height: calc(18px + 1vmin); } }
  @media (min-width: 600px) {
    .fonts_subtitle__1PQFt {
      font-size: calc(16px + 1vmin);
      line-height: calc(18px + 1vmin); } }
  @media (min-width: 960px) {
    .fonts_subtitle__1PQFt {
      font-size: calc(16px + 0vmin);
      line-height: calc(18px + 0vmin); } }
  @media (min-width: 1280px) {
    .fonts_subtitle__1PQFt {
      font-size: calc(16px + 0vmin);
      line-height: calc(18px + 0vmin); } }
  @media (min-width: 1920px) {
    .fonts_subtitle__1PQFt {
      font-size: 16px;
      line-height: 18px; } }

.fonts_subtitle-2__3rL5T {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal; }
  @media (min-width: 0px) {
    .fonts_subtitle-2__3rL5T {
      font-size: calc(14px + 1vmin);
      line-height: calc(24px + 1vmin); } }
  @media (min-width: 600px) {
    .fonts_subtitle-2__3rL5T {
      font-size: calc(14px + 1vmin);
      line-height: calc(24px + 1vmin); } }
  @media (min-width: 960px) {
    .fonts_subtitle-2__3rL5T {
      font-size: calc(14px + 0vmin);
      line-height: calc(24px + 0vmin); } }
  @media (min-width: 1280px) {
    .fonts_subtitle-2__3rL5T {
      font-size: calc(14px + 0vmin);
      line-height: calc(24px + 0vmin); } }
  @media (min-width: 1920px) {
    .fonts_subtitle-2__3rL5T {
      font-size: 14px;
      line-height: 24px; } }

.fonts_button__35NxA {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase; }
  @media (min-width: 0px) {
    .fonts_button__35NxA {
      font-size: calc(14px + 1vmin);
      line-height: calc(16px + 1vmin); } }
  @media (min-width: 600px) {
    .fonts_button__35NxA {
      font-size: calc(14px + 1vmin);
      line-height: calc(16px + 1vmin); } }
  @media (min-width: 960px) {
    .fonts_button__35NxA {
      font-size: calc(14px + 0vmin);
      line-height: calc(16px + 0vmin); } }
  @media (min-width: 1280px) {
    .fonts_button__35NxA {
      font-size: calc(14px + 0vmin);
      line-height: calc(16px + 0vmin); } }
  @media (min-width: 1920px) {
    .fonts_button__35NxA {
      font-size: 14px;
      line-height: 16px; } }

.fonts_caption__1IVWm {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.4px;
  text-transform: none; }
  @media (min-width: 0px) {
    .fonts_caption__1IVWm {
      font-size: calc(12px + 0.5vmin);
      line-height: calc(16px + 0.5vmin); } }
  @media (min-width: 600px) {
    .fonts_caption__1IVWm {
      font-size: calc(12px + 0.5vmin);
      line-height: calc(16px + 0.5vmin); } }
  @media (min-width: 960px) {
    .fonts_caption__1IVWm {
      font-size: calc(12px + 0vmin);
      line-height: calc(16px + 0vmin); } }
  @media (min-width: 1280px) {
    .fonts_caption__1IVWm {
      font-size: calc(12px + 0vmin);
      line-height: calc(16px + 0vmin); } }
  @media (min-width: 1920px) {
    .fonts_caption__1IVWm {
      font-size: 12px;
      line-height: 16px; } }

.fonts_overline__3sFbq {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: calc(10px + 2vmin);
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase; }

.fonts_panel-title__1l_x3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #255884; }

.fonts_form-caption__3qyiE {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #255884; }

.nav_navLinks__1bRo_ {
  height: 100%;
  border-bottom: 1px solid #255884; }

@media (min-width: 0px) {
  .nav_nav-desktop__3ukB0 {
    display: none; } }

@media (min-width: 1280px) {
  .nav_nav-desktop__3ukB0 {
    display: flex; } }

@media (min-width: 0px) {
  .nav_nav-mobile__1zBnF {
    display: flex; } }

@media (min-width: 1280px) {
  .nav_nav-mobile__1zBnF {
    display: none; } }

.nav_nav-button__1Qljj {
  position: relative;
  top: 15px; }

.nav_userWidget__3R_uU {
  text-align: right; }

.drawers_sidebar-list__3opcn {
  height: 100%;
  color: #255884; }

.drawers_sidebar__2AWKB {
  box-shadow: -5px 0px 10px #255884;
  background-color: #FCFCFC; }

.button_fat__vMiKJ {
  padding: 10px 15px;
  border-radius: 25px; }

.logo_logo__351JE {
  margin-top: 4vh;
  max-width: 95vw
}

.auth_form__1-zN6 {

}

.async_LinearAsync__14tqk {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5000; }

.async_hidden__2mqyJ {
  visibility: hidden; }

.async_super-hidden__3jgyR {
  display: none; }

.input_checkbox__2S5J0 {
  padding: 8px; }

.input_textfield__Mi5SP, .input_selectfield__1pGKe {
  background: #fff; }
  .input_textfield__Mi5SP:hover fieldset, .input_selectfield__1pGKe:hover fieldset {
    border-color: #0086C0 !important; }

.home_home__lGm1V {
  padding-top: 20px; }

.home_account-column-1__35VTh {
  text-align: right;
  color: rgba(0, 0, 0, 0.6); }

.home_recentWarrantyHeader__1di00 {
  border-bottom: 1px solid rgba(167, 169, 172, 0.25); }

.home_recentWarrantyHeader__1di00 > div {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.6); }

.panel_container__27rQN {
  padding: 10px 25px;
  border: 1px solid rgba(167, 169, 172, 0.25);
  box-shadow: none;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 0;
  width: 100%; }
  .panel_container__27rQN.panel_no-bg__1T3cR {
    background: none; }
  .panel_container__27rQN.panel_no-border__2wO_c {
    border: 0; }
  .panel_container__27rQN.panel_accent-border__3IRYS {
    box-shadow: 0px 2px 0px #0086C0; }
  .panel_container__27rQN .panel_title__RfM8r {
    padding: 10px; }
  .panel_container__27rQN .panel_content__3-i67 {
    padding: 20px 0; }

.warranties_container__3f0GZ {
  padding-top: 20px; }
  .warranties_container__3f0GZ h6 {
    padding-bottom: 20px; }

.tables_table__x55xJ .tables_header__1PsZB {
  color: rgba(0, 0, 0, 0.38); }
  .tables_table__x55xJ .tables_header__1PsZB .tables_header-button__mWLu2 {
    margin: 0;
    text-transform: inherit;
    justify-content: start; }

.tables_table__x55xJ .tables_header-row__2txIx {
  border-bottom: 1px solid rgba(167, 169, 172, 0.25);
  margin-bottom: 20px;
  padding: 8px 0; }

.tables_table__x55xJ .tables_cell__h5U5l {
  font-weight: normal; }

.tables_table-input__1GPSQ {
  background-color: transparent; }

.tables_table-input__1GPSQ:before, .tables_table-input__1GPSQ:after {
  border-color: #0086C0 !important; }

.tables_paging__3dYYj {
  padding: 10px; }

.form_form-control__14Zv2 {
  margin: 0; }
  .form_form-control__14Zv2 .form_disabled__1hHqY {
    color: rgba(0, 0, 0, 0.38) !important; }
    .form_form-control__14Zv2 .form_disabled__1hHqY span {
      color: rgba(0, 0, 0, 0.38) !important; }

.form_stepper-next-button__b6kqe {
  padding: 10px;
  border-radius: 25px; }

.stepper_stepper__1UJnc {
  background: none; }

.stepper_step__3UTkP .stepper_step-icon__10R70 {
  font-size: 30px; }

.stepper_step__3UTkP .stepper_step-icon-text__21DO4 {
  font-weight: 700;
  font-family: Montserrat; }

.stepper_step__3UTkP .stepper_step-label__YQFSK {
  color: #255884; }

.stepper_step__3UTkP.stepper_step-active__1KIM7 .stepper_step-label__YQFSK {
  font-weight: 700;
  color: #0086C0; }

.stepper_step__3UTkP.stepper_step-active__1KIM7 .stepper_step-icon__10R70 {
  color: #0086C0;
  font-size: 37px; }

.stepper_step__3UTkP.stepper_step-completed__1iXzR .stepper_step-icon__10R70 {
  color: #6BBE02; }

.stepper_step__3UTkP.stepper_step-error__8BmZU .stepper_step-icon__10R70 {
  color: #f44336 !important; }

.stepper_step__3UTkP.stepper_step-disabled__33sUE .stepper_step-icon__10R70 {
  color: rgba(0, 0, 0, 0.38); }

.orders_header-container__1OMZG {
  padding: 0px 56px 0 24px; }

.orders_header__3BoIx {
  color: rgba(0, 0, 0, 0.38); }

.orders_details__1tnwY {
  color: #0086C0; }

.orders_warranty-column-1__32ave {
  color: rgba(0, 0, 0, 0.38);
  font-weight: 700;
  text-align: right; }

.orders_expansion-panel__235OC {
  box-shadow: none;
  border-radius: inherit;
  border: 1px solid rgba(167, 169, 172, 0.25); }

.orders_order-divider__2v21E {
  margin-top: 24px; }

.orders_order-edit-button__2BlqR, .orders_order-edit-button__2BlqR.orders_active__1K8TA {
  color: #0086C0; }

.orders_order-delete-button__3mbo5, .orders_order-delete-button__3mbo5.orders_active__1K8TA {
  color: #EB5757; }

.square_form-container__J_-GX {
  padding: 0 15px; }
  .square_form-container__J_-GX .square_square__3583S {
    padding: 0;
    margin: 0;
    background: transparent none; }
  .square_form-container__J_-GX .square_cc-field-container__2p1TC {
    max-width: 20em;
    margin: -8px auto;
    text-align: left; }

.square_logo__226tO {
  margin: 0 auto;
  width: 40px; }

.square_dialogTitle__3NVj6 {
  color: #255884; }

.forms_form__2JOON {
  padding-bottom: 15px; }
  .forms_form__2JOON .forms_covered__2bWUd {
    color: #0086C0;
    font-weight: 700; }
  .forms_form__2JOON .forms_warranty-column-1__12Gt8 {
    text-align: right; }

.forms_summary__bF3s6 .forms_warranty-column-1__12Gt8 .forms_caption__1U346 {
  color: #0086C0;
  font-weight: 700; }

.forms_spinner__26bLU {
  margin-right: 4px; }

.forms_warranty-form-label__2bAl0 {
  width: 100%; }


.faq_question__3fv9Z {
  color: #255884; }

.faq_panel__2t4oM {
  box-shadow: none;
  border: 1px solid rgba(167, 169, 172, 0.25); }

.docs_panel__1ISll {
  box-shadow: none;
  border: 1px solid rgba(167, 169, 172, 0.25); }

.docs_link__28YtJ {
  display: block;
  word-break: break-all;
  text-align: center; }

.docs_icon__3426V {
  display: block;
  margin: 0 auto;
  font-size: 5em; }

.panel_context-panel-container__1HPqg h6 {
  text-align: center;
  margin: 5vh 0; }

.panel_context-panel-container__1HPqg .panel_divider__3qGG6 {
  background-color: white;
  width: 50px;
  height: 2px;
  margin: 0 auto; }

.panel_context-panel-container__1HPqg .panel_context-panel__1l8Wr {
  width: 90%;
  color: #FFFFFF;
  padding: 20px;
  margin: 10px 0;
  flex-wrap: wrap; }
  @media (min-width: 960px) {
    .panel_context-panel-container__1HPqg .panel_context-panel__1l8Wr {
      width: 200px; } }
  .panel_context-panel-container__1HPqg .panel_context-panel__1l8Wr .panel_content__1oMsh {
    overflow-wrap: break-word; }

.panel_context-panel-container__1HPqg .panel_left-panel__2Pa-q {
  background: #0086C0; }
  .panel_context-panel-container__1HPqg .panel_left-panel__2Pa-q .panel_title__TJ7Bn {
    text-align: center; }

.panel_context-panel-container__1HPqg .panel_right-panel__2Dr4h:only-child {
  margin-top: 20vh; }

.panel_context-panel-container__1HPqg .panel_right-panel__2Dr4h {
  background: #255884;
  border-radius: 10px;
  position: relative; }
  .panel_context-panel-container__1HPqg .panel_right-panel__2Dr4h .panel_divider__3qGG6 {
    display: none; }
  .panel_context-panel-container__1HPqg .panel_right-panel__2Dr4h .panel_title__TJ7Bn {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-transform: none; }

.panel_context-panel-container__1HPqg .panel_pending-order__13Ueq {
  background: #fff;
  border: 1px solid #0086C0;
  color: #255884; }

