@import 'breakpoints';

$font-size-ratio-xs: 1vmin;
$font-size-ratio-sm: 1vmin;
$font-size-ratio-md: 0vmin;
$font-size-ratio-lg: 0vmin;

$line-height-ratio-xs: 1vmin;
$line-height-ratio-sm: 1vmin;
$line-height-ratio-md: 0vmin;
$line-height-ratio-lg: 0vmin;



@mixin font-size-gen($font-size, $line-height: null, $factor: 1) {
  @include xs {
    font-size: calc(#{$font-size} + #{$factor * $font-size-ratio-xs});
    @if $line-height {
      line-height: calc(#{$line-height} + #{$factor * $line-height-ratio-xs});
    }
  }
  @include sm {
    font-size: calc(#{$font-size} + #{$factor * $font-size-ratio-sm});
    @if $line-height {
      line-height: calc(#{$line-height} + #{$factor * $line-height-ratio-sm});
    }
  }
  @include md {
    font-size: calc(#{$font-size} + #{$factor * $font-size-ratio-md});
    @if $line-height {
      line-height: calc(#{$line-height} + #{$factor * $line-height-ratio-md});
    }
  }
  @include lg {
    font-size: calc(#{$font-size} + #{$factor * $font-size-ratio-lg});
    @if $line-height {
      line-height: calc(#{$line-height} + #{$factor * $line-height-ratio-lg});
    }
  }
  @include xl {
    font-size: $font-size;
    @if $line-height {
      line-height: $line-height;
    }
  }
}
