@import 'colors';

.container {
  padding: 10px 25px;
  border: 1px solid rgba(167,169,172, .25);
  box-shadow: none;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 0;
  width: 100%;
  //height: 100%;

  &.no-bg {
    background: none;
  }

  &.no-border {
    border: 0;
  }

  &.accent-border {

    box-shadow: 0px 2px 0px $secondary;
  }

  .title {
    padding: 10px;
  }
  .content {
    padding: 20px 0;
  }
}
