@import 'colors';

.home {
  padding-top:20px;
}


.account-column-1 {
  text-align: right;
  color: $black-medium;
}

.recentWarrantyHeader {
  border-bottom: 1px solid $gray-light;
}
.recentWarrantyHeader>div {
  font-size: 10px;
  color: $black-medium;
}
