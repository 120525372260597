@import 'colors';

.header-container {
  padding: 0px 56px 0 24px;
}
.header {
  color: $black-disabled;
}

.details {
  color: $secondary;
}

.warranty-column-1 {
  color: $black-disabled;
  font-weight: 700;
  text-align: right;
}

.expansion-panel {
  box-shadow: none;
  border-radius: inherit;
  border: 1px solid $gray-light;
}

.order-divider {
  margin-top: 24px;
}

.order-edit-button, .order-edit-button.active {
  color: $secondary;
}

.order-delete-button, .order-delete-button.active {
  color: $red;
}
