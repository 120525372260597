@import 'colors';

.question {
  color: $primary;
}

.panel {
  box-shadow: none;
  border: 1px solid $gray-light;
}
