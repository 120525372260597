@import 'colors';

.table {

  .header {
    color: $black-disabled;

    .header-button {
      margin: 0;
      text-transform: inherit;
      justify-content: start;

      &:disabled {

      }
    }
  }

  .header-row {
    border-bottom: 1px solid $gray-light;
    margin-bottom: 20px;
    padding: 8px 0;
  }

  .cell {
    font-weight: normal;
  }
}

.table-input {
  background-color: transparent;
}

.table-input:before, .table-input:after {
  border-color: $secondary !important;
}

.paging {
  padding: 10px;
}
