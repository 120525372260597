@import 'colors';

.checkbox {
  padding: 8px;
}

.formhelper-text {

}

.textfield, .selectfield {
  background: #fff;
  &:hover fieldset {
    border-color: $secondary !important;
  }
}

.textfield-input {
  //background: #FFF;
}
