@import 'colors';

.stepper {
  background: none;
}
.step{

  .step-icon {
    font-size: 30px;
  }

  .step-icon-text {
    font-weight: 700;
    font-family: Montserrat;
  }

  .step-label {
    color: $primary
  }

  &.step-active {
    .step-label {
      font-weight: 700;
      color: $secondary;
    }
    .step-icon {
      color: $secondary;
      font-size: 37px;
    }
  }

  &.step-completed {
    .step-icon {
      color: #6BBE02;
    }
  }

  &.step-error {
    .step-icon {
      color: #f44336 !important;
    }
  }

  &.step-disabled {
    .step-icon {
      color: $black-disabled;
    }
  }


}
