
.flex-grow {
  flex-grow: 1;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

img{
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}


a.active {
  color: $secondary
}

body {
  color: $primary
}


h1 {
  @include font-size-gen($font-size:48px);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -1.5px;
}

h2 {
  @include font-size-gen($font-size:60px);
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.5px;
}

h3 {
  @include font-size-gen($font-size:48px);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
}

h4 {
  @include font-size-gen($font-size:34px);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.25px;
}

h5 {
  @include font-size-gen($font-size: 24px, $line-height:24px);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin: 10px 0;
}

h6 {
  @include font-size-gen($font-size:20px);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

body {
  @include font-size-gen($font-size:18px, $line-height:28px);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.5px;
}

small {
  @include font-size-gen($font-size:14px, $line-height:20px);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0.25px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
