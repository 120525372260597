@import 'fonts';
@import 'colors';

.textfield {
  font-family: inherit;
  // Not needed, taken care of by materials.ui
  //@include font-textfield;
}

.textfield-label {
  // Not needed, taken care of by materials.ui
  //@include font-textfield-label;
}

.subtitle {
  @include font-size-gen($font-size: 16px, $line-height:18px);
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 1px;
}

.subtitle-2 {
  @include font-size-gen($font-size: 14px, $line-height:24px);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

.button {
  @include font-size-gen($font-size: 14px, $line-height:16px);
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
}

.caption {
  $font-size: 12px;
  $line-height:16px;
  $responsive-factor: .5;
  @include font-size-gen($font-size, $line-height, $responsive-factor);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.4px;
  text-transform: none;
}

.overline {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: calc(10px + 2vmin);
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.panel-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $primary;
}

.form-caption {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: $primary;
}
