
$primary: #255884;
$secondary: #0086C0;

// blacks
$black-high: rgba(0, 0, 0, 0.87);  // high emphasis
$black-medium: rgba(0, 0, 0, 0.6); // black-medium
$black-disabled: rgba(0, 0, 0, 0.38);

// grays
$gray-light: rgba(167, 169, 172, .25); // secondary-50

$red: #EB5757;

$background: #FCFCFC;
$panel: #FFFFFF;

$select-highlight: #E8E8E8;
