// small smartphones (portrait view)
$screen-xs-min: 0px;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 600px;

// Small tablets (portrait view)
$screen-md-min: 960px;

// Tablets and small desktops
$screen-lg-min: 1280px;

// Large tablets and desktops
$screen-xl-min: 1920px;

// Extra Small devices
@mixin xs {
   @media (min-width: #{$screen-xs-min}) {
       @content;
   }
}

// Small devices
@mixin sm {
   @media (min-width: #{$screen-sm-min}) {
       @content;
   }
}

// Medium devices
@mixin md {
   @media (min-width: #{$screen-md-min}) {
       @content;
   }
}

// Large devices
@mixin lg {
   @media (min-width: #{$screen-lg-min}) {
       @content;
   }
}

// Extra large devices
@mixin xl {
   @media (min-width: #{$screen-xl-min}) {
       @content;
   }
}

// Custom devices
@mixin rwd($screen) {
   @media (min-width: $screen+'px') {
       @content;
   }
}
