@import 'colors';

.form {
  padding-bottom: 15px;

  .sold-date {

  }

  .covered {
    color: $secondary;
    font-weight: 700;
  }

  .warranty-column-1 {
    text-align: right;
  }
}

.summary {
  .warranty-column-1 {
    .caption {
      color: $secondary;
      font-weight: 700;
    }
  }
}

.spinner {
  margin-right:4px;
}

.warranty-form-label {
  width: 100%;
}
