@import 'colors';

.checkbox {

}

.radio {

}

.form-control {
  margin: 0;

  .disabled {
    color: $black-disabled !important;

    & span {
      color: $black-disabled !important;
    }
  }
}

.stepper-next-button {
  padding: 10px;
  border-radius: 25px;
}

.text {

}
