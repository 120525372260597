@import 'breakpoints';
@import 'colors';

.context-panel-container {
  h6 {
    text-align: center;
    margin: 5vh 0;
  }

  .divider {
    background-color: white;
    width: 50px;
    height: 2px;
    margin: 0 auto;
}

  .context-panel {
    width: 90%;
    @include md {
      width: 200px;
    }
    color: #FFFFFF;
    padding: 20px;
    margin: 10px 0;
    flex-wrap: wrap;


    .content {
      overflow-wrap: break-word;
    }
  }
  .left-panel {
    background: #0086C0;

    .title {
      text-align: center;
    }
  }
  .right-panel:only-child {
    margin-top: 20vh;
  }

  .right-panel {
    background: #255884;
    border-radius: 10px;
    position: relative;

    .divider {
      display: none;
    }

    .title {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      text-transform: none;
    }
  }

  .pending-order {
    background: #fff;
    border: 1px solid $secondary;
    color: $primary;
  }
}
