@import 'breakpoints';
@import 'fonts';
@import 'colors';
@import 'defaults';


.App {
  background-color: $background;
  font-size: calc(10px + 2vmin);
  color: $primary;
  min-height: 100vh;
  padding-top: 20px;

  .bold {
    font-weight: 700 ;
  }


  .App-header {
    text-align:center;
  }

  .App-content {
    padding-bottom: 20px;
  }

  .App-footer {
    background: #255884;
    color: white;
    padding: 5px;
    text-align:center;

    a {
      color: #fff;
    }
  }

  .App-link {
    color: #61dafb;
  }
}
