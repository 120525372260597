@import 'colors';

.sidebar-list {
  height: 100%;
  color: $primary;
}

.sidebar {
  box-shadow: -5px 0px 10px $primary;
  background-color: $background;
}
