@import 'colors';


.panel {
  box-shadow: none;
  border: 1px solid $gray-light;
}

.link {
  display: block;
  word-break: break-all;
  text-align: center;
}

.icon {
  display: block;
  margin: 0 auto;
  font-size: 5em;
}
