@import 'colors';

.form-container {
  padding: 0 15px;

  .square {
    padding: 0;
    margin: 0;
    background: transparent none;
  }

  .cc-field-container {
    max-width: 20em;
    margin: -8px auto;
    text-align: left;
  }
}

.logo {
  margin: 0 auto;
  width: 40px;
}

.dialogTitle {
  color: $primary;
}
