@import 'fonts';

.navLinks {
  height: 100%;
  border-bottom: 1px solid #255884;
}
.nav-desktop {
  @include xs {
    display: none;
  }
  @include lg {
    display: flex;
  }
}

.nav-mobile {
  @include xs {
    display: flex;
  }
  @include lg {
    display: none;
  }
}

.nav-button {
  @include md {
    //margin: 0 10px;
  }
  @include lg {
    //margin: 0 10px;
  }
  @include xl {
    //margin: 0 20px;
  }
  // font-family: Montserrat;
  // font-style: normal;
  // font-weight: normal;
  // letter-spacing: 1px;
  // text-transform: none;
  // font-size: 16px;
  // line-height: 18px;
  position: relative;
  top: 15px;

}

.userWidget {
  text-align:right;
}

.appLogo {

}
